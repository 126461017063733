<template>
  <div
    v-if="vehicleInfo && vehicleInfo.Vehicle"
    class="shipmentDetail d-flex align-items-center"
  >
    <div class="row">
      <div
        class="mb-3"
        :class="vehicleInfo.VehicleType ? 'col-md-6' : 'col-md-12'"
      >
        <div class="detailSection">
          <div>
            <span style="font-size: 16px; font-weight: 600"> Araç Tipi: </span>
            <span>
              {{
                vehicleInfo.Vehicle.TypeName
                  ? vehicleInfo.Vehicle.TypeName
                  : "Tır, Kamyon veya Kamyonet"
              }}
            </span>
          </div>
          <div>
            <span style="font-size: 16px; font-weight: 600">
              Araç Sayısı:
            </span>
            <span>
              {{ vehicleInfo.VehicleCount }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <div
          v-if="vehicleInfo.VehicleType"
          class="detailSection d-flex justify-content-center h-100"
        >
          <img
            v-if="vehicleInfo.VehicleType == 0"
            src="@/assets/images/tir.svg"
            alt=""
            style="width: 125px"
          />
          <img
            v-else-if="vehicleInfo.VehicleType == 1"
            src="@/assets/images/kamyon.svg"
            alt=""
            class="align-self-center"
            style="max-width: 180px"
          />
          <img
            v-else
            src="@/assets/images/kamyonet-kasali.svg"
            alt=""
            class="align-self-center"
            style="max-width: 180px"
          />
        </div>
      </div>
      <div class="col-md-12 mb-3">
        <div class="detailSection text-left">
          <span style="font-size: 16px; font-weight: 600">Marka Model:</span>
          <br />
          <span>
            {{ vehicleInfo.Vehicle.Brand }} / {{ vehicleInfo.Vehicle.Model }}
          </span>
        </div>
      </div>
      <div class="col-md-12">
        <div class="detailSection text-left">
          <span style="font-size: 16px; font-weight: 600">Dorse Cinsi: </span>
          <span>{{ getTrailerTypes() ? getTrailerTypes() : "Tır, Kamyon veya Kamyonet" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["vehicleInfo"],
  methods: {
    getTrailerTypes() {
      return this.trailers
        ?.filter((trailer) =>
          this.vehicleInfo.TrailerIds.find(
            (vehicleTrailerId) => vehicleTrailerId == trailer?.ID
          )
        )
        .map((filteredTrailer) => filteredTrailer.Title).toString().split(",").join(", ");
    },
  },
  computed: {
    trailers: {
      get() {
        return this.$store.getters["trailerDetails"];
      },
    },
  },
};
</script>

<style scoped>
.shipmentDetail {
  border-radius: 16px;
  background-color: #4d668a;
  width: 100%;
  margin-top: 20px;
  padding: 20px 30px;
  margin-bottom: 20px;
}
.detailDivider {
  border-bottom: 1px dashed white;
  margin: 15px 0;
}
.detailSection {
  background-color: #2d405a;
  border-radius: 16px;
  padding: 10px 15px;
}
</style>
