<template>
  <div v-if="bankInfo" class="shipmentDetail">
    <div class="row">
      <div class="col-md-12">
        <div class="detailSection">
          <span style="font-size: 16px; font-weight: 600"> Banka Adı </span>
          <br />
          <span>
            {{ bankInfo.Title }}
          </span>
        </div>
      </div>
      <div class="col-md-12">
        <div class="detailSection mt-2">
          <span style="font-size: 16px; font-weight: 600"> IBAN </span>
          <br />
          <span>
            {{ bankInfo.IBAN }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["bankInfo"],
};
</script>

<style scoped>
.shipmentDetail {
  position: relative;
  border-radius: 16px;
  background-color: #4d668a;
  width: 100%;
  margin-top: 20px;
  padding: 20px 30px;
  margin-bottom: 20px;
}

.detailDivider {
  border-bottom: 1px dashed white;
  margin: 15px 0;
}
.detailSection {
  background-color: #2d405a;
  border-radius: 16px;
  padding: 10px 15px;
}
</style>
